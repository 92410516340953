'use client'

import Navigation from './Navigation'
import FAQS from './FAQS'
import Subscription from './Subscription'
import Contact from './Contact'
import Landing from './Landing'
import React, { useState, useEffect } from 'react';
import apiClient from '../apiClient'
import Footer from './Footer'
import AudienceInformation from './AudienceInformation'

export default function FrontpageBase() {
    const [translations, setTranslations] = useState([]); // Stores translations by subsection
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    // Fetch translations on mount
    useEffect(() => {
      fetchTranslations();
    }, []);
  
  
    const fetchTranslations = async () => {
      try {
        const response = await apiClient.get(`/translations/`);
        const data = response.data;
        const groupedData = data.reduce((acc, item) => {
          const existingKey = acc.find((key) => key.key === item.key);
          if (existingKey) {
            existingKey.translations.push(item);
          } else {
            acc.push({
              key: item.key,
              keyId: item.keyId,
              parent_category: item.parent_category,
              translations: item.translations,
            });
          }
          return acc;
        }, []);
        setTranslations(groupedData);
      } catch (error) {
        console.error('Failed to fetch translations:', error);
      }
    };
  
    const getTranslation = (key) => {
      if (translations) {
        const translationItem = translations.find((t) => t.key === key);
        if (!translationItem) return key; // Fallback to key if translation is not found
        const translationForLanguage = translationItem.translations.find((t) => t.language === selectedLanguage);
        return translationForLanguage ? translationForLanguage.translation : key; // Fallback to key if language translation is missing
      } else {
        return getTranslation("loading");
      }
    };

    return (
        <>
            <Navigation getTranslation={getTranslation} selectedLanguage={selectedLanguage} handleLanguageSelect={setSelectedLanguage} />
            <div className="relative isolate">
                {/* Background Effect */}
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
                </div>
                <Landing getTranslation={getTranslation} />
                <AudienceInformation getTranslation={getTranslation} />
                <FAQS getTranslation={getTranslation} />
                <Contact getTranslation={getTranslation} />
            </div>
            <Footer getTranslation={getTranslation} />
        </>
    )
}