export default function Landing({ getTranslation }) {
    return (
        <>
            <img
                className="w-screen h-screen -z-10 opacity-30 absolute top-0 object-cover"
                src="/SP_BG_LANDING.jpg"
                alt={getTranslation('landing_background_alt')}
            />
            <div className="relative flex items-center justify-center h-screen">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="text-center bg-white bg-opacity-30 p-6 sm:p-10 rounded-lg">
                        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900">
                            {getTranslation('landing_title')}
                        </h1>
                        <div className="w-full flex items-center justify-center pt-8 sm:pt-12 md:pt-16">
                            <p className="mt-6 text-base sm:text-lg md:text-xl leading-7 sm:leading-8 text-gray-600 max-w-2xl">
                                {getTranslation('landing_subtitle')}
                            </p>
                        </div>
                        <div className="mt-8 sm:mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4">
                            <a
                                href="https://www.reader.symphonypages.com/"
                                className="w-full sm:w-auto rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center"
                            >
                                {getTranslation('start_now')}
                            </a>
                            <a href="#learnmore" className="text-sm font-semibold leading-6 text-gray-900">
                                {getTranslation('learn_more_link')} <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
