'use client';

import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

export default function Navigation({ getTranslation, selectedLanguage, handleLanguageSelect }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [learnMoreOpen, setLearnMoreOpen] = useState(false); // Dropdown state

    const navigation = [
        { name: getTranslation('symphony_reader'), href: 'https://www.reader.symphonypages.com/' },
        { name: getTranslation('learn_more'), href: '/#learnmore', dropdown: true },
        { name: getTranslation('about_us'), href: '#faqs' },
        { name: getTranslation('contact'), href: '#contact' },
    ];

    const learnMoreLinks = [
        { name: getTranslation('for_users'), href: '#users' },
        { name: getTranslation('for_authors'), href: '#authors' },
        { name: getTranslation('for_publishers'), href: '#publishers' },
    ];

    const languages = [
        { code: 'en', label: getTranslation('en'), Flag: '/united-kingdom.png' },
        { code: 'de', label: getTranslation('de'), Flag: '/germany.png' },
        { code: 'fr', label: getTranslation('fr'), Flag: '/france.png' },
        { code: 'it', label: getTranslation('it'), Flag: '/italy.png' },
    ];

    return (
        <header className="bg-white shadow-lg fixed top-0 w-full max-w-[100vw] z-50">
            <nav aria-label="Global" className="flex max-w-screen items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5">
                        <span className="sr-only">{getTranslation('your_company')}</span>
                        <img
                            alt={getTranslation('company_logo')}
                            src="/SymphonyPages_icon_1.jpg"
                            className="h-12 w-auto"
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">{getTranslation('open_menu')}</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) =>
                        item.dropdown ? (
                            <div key="learn-more" className="relative">
                                <button
                                    onClick={() => setLearnMoreOpen(!learnMoreOpen)}
                                    className="text-sm font-semibold leading-6 text-gray-900"
                                >
                                    {item.name}
                                </button>
                                {learnMoreOpen && (
                                    <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            {learnMoreLinks.map((link) => (
                                                <a
                                                    key={link.name}
                                                    href={link.href}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    onClick={() => setLearnMoreOpen(false)}
                                                >
                                                    {link.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        )
                    )}
                </div>
                <div className="flex justify-center items-center space-x-4 absolute right-40 top-6">
                    {languages.map(({ code, label, Flag }) => (
                        <div
                            key={code}
                            onClick={() => handleLanguageSelect(code)}
                            className={`cursor-pointer p-1 rounded-md ${
                                selectedLanguage === code ? 'bg-indigo-400' : 'bg-gray-100'
                            } hover:shadow-lg hover:scale-105 transform transition-all`}
                            title={label}
                        >
                            <img src={Flag} className="w-6 h-6" />
                        </div>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="https://www.reader.symphonypages.com/login" className="text-sm font-semibold leading-6 text-gray-900">
                        {getTranslation('log_in')} <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">{getTranslation('your_company')}</span>
                            <img
                                alt={getTranslation('company_logo')}
                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                className="h-8 w-auto"
                            />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">{getTranslation('close_menu')}</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) =>
                                    item.dropdown ? (
                                        <div key="learn-more-mobile" className="space-y-1">
                                            <span className="text-gray-900 font-semibold text-lg">
                                                {getTranslation('learn_more')}
                                            </span>
                                            {learnMoreLinks.map((link) => (
                                                <a
                                                    key={link.name}
                                                    href={link.href}
                                                    className="block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    onClick={() => setMobileMenuOpen(false)}
                                                >
                                                    {link.name}
                                                </a>
                                            ))}
                                        </div>
                                    ) : (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            {item.name}
                                        </a>
                                    )
                                )}
                            </div>
                            <div className="py-6">
                                <a
                                    href="https://www.reader.symphonypages.com/login"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {getTranslation('log_in')}
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}
