import React from 'react';
import { BookOpenIcon, MusicalNoteIcon } from '@heroicons/react/20/solid';

const ForAuthors = ({ getTranslation }) => {
  return (
    <div className="bg-white" id="forauthors">
      <div className="mx-auto w-full">
        <div className="flex-col flex lg:flex-row items-center justify-center">
          {/* Features Section */}
          <div className="grid p-6 w-full lg:max-w-2xl flex-col">
            <div className="bg-indigo-100 p-4 rounded-lg bg-opacity-50">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('how_it_works')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('how_it_works_description')}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('honoring_authors_work')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('honoring_authors_work_description')}
              </p>
            </div>
          </div>

          {/* Coming Soon Section */}
          <div className="text-center w-full lg:w-1/3 p-6 flex justify-center">
            <div className="bg-yellow-100 rounded-lg bg-opacity-50 p-4 lg:w-2/3">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('coming_soon')}
              </h3>
              <ul className="mt-8 space-y-4 text-base leading-7 text-gray-600">
                <li>{getTranslation('coming_soon_music_selection')}</li>
                <li>{getTranslation('coming_soon_pricing')}</li>
                <li>{getTranslation('coming_soon_engage_audience')}</li>
                <li>{getTranslation('coming_soon_creative_control')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForAuthors;
