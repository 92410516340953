import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

export default function Contact({ getTranslation }) {
  return (
    <div className="relative isolate bg-white py-20 lg:py-28 lg:h-screen" id="contact">
      <img
        className="w-full h-full -z-10 opacity-30 absolute top-0 object-cover"
        src="/SP_CONTACT_BG.jpeg"
        alt={getTranslation('contact_background_alt')}
      />
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-y-0 lg:gap-x-12 px-4 sm:px-6 lg:px-8">
        {/* Contact Information Section */}
        <div className="relative px-6 pb-10 pt-20 sm:pt-24 lg:px-8 lg:py-20 bg-white bg-opacity-80 rounded-lg">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">{getTranslation('contact_us')}</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">{getTranslation('contact_us_description')}</p>
            <dl className="mt-10 space-y-6 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">{getTranslation('address')}</span>
                  <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  {getTranslation('company_name')}
                  <br />
                  {getTranslation('address_line_1')}
                  <br />
                  {getTranslation('address_line_2')}
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dd>
                  <a href="mailto:info@symphonypages.com" className="hover:text-gray-900">
                    {getTranslation('email')}
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        {/* Contact Form Section */}
        <form action="#" method="POST" className="relative px-6 pb-10 pt-20 sm:pt-24 lg:px-8 lg:py-20 bg-white bg-opacity-50 rounded-lg">
          <div className="mx-auto max-w-xl lg:ml-auto lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                  {getTranslation('first_name')}
                </label>
                <div className="mt-2.5">
                  <input
                    id="first-name"
                    name="first-name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                  {getTranslation('last_name')}
                </label>
                <div className="mt-2.5">
                  <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  {getTranslation('email')}
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                  {getTranslation('phone_number')}
                </label>
                <div className="mt-2.5">
                  <input
                    id="phone-number"
                    name="phone-number"
                    type="tel"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                  {getTranslation('message')}
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {getTranslation('send_message')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
