import './App.css';
import FrontpageBase from './frontpage/FrontpageBase';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

function ReaderRedirect() {
  useEffect(() => {
    window.location.href = "https://www.reader.symphonypages.com";
  }, []);

  return (
    <div>
      <p>Redirecting to Reader...</p>
      <a href="https://www.reader.symphonypages.com">
        Click here if you are not redirected automatically.
      </a>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="">
        <Routes>
          {/* Main Route */}
          <Route path="/" element={<FrontpageBase />} />

          {/* Redirect for /reader */}
          <Route path="/reader" element={<ReaderRedirect />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
