import axios from 'axios';

const debug = false

let API_BASE_URL

if(debug){
  API_BASE_URL = 'http://localhost:8000/api/reader/'; // Adjust base URL to match your API
}else{
  API_BASE_URL = 'https://symphonypages-webapp-backend-production-cehbhybtbnfydjeq.westeurope-01.azurewebsites.net/api/reader/'; // Adjust base URL to match your API
  }

// Retrieve the access token from local storage
const getAccessToken = () => localStorage.getItem('access_token');

// Store the token in local storage
const setAccessToken = (token) => localStorage.setItem('access_token', token);

// Remove the token from local storage
const clearToken = () => localStorage.removeItem('access_token');

// Create an Axios instance
const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Track if token refresh is in progress
let isRefreshing = false;
let refreshSubscribers = [];

// Function to call after refreshing token
const onRefreshed = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

// Subscribe to token refresh event
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// Interceptor to add token to requests
apiClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle token refresh and unauthorized errors
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(apiClient(originalRequest));
          });
        });
      }

      isRefreshing = true;
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        try {
          const response = await axios.post(`${API_BASE_URL}token/refresh/`, {
            refresh: refreshToken,
          });
          const newAccessToken = response.data.access;
          setAccessToken(newAccessToken);
          isRefreshing = false;
          onRefreshed(newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return apiClient(originalRequest);
        } catch (refreshError) {
          clearToken();
          localStorage.removeItem('refresh_token');
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      } else {
        clearToken();
      }
    }

    return Promise.reject(error);
  }
);


// Utility function for login
export const login = async (username, password) => {
  try {
    const response = await apiClient.post('token/', { username, password });
    const { access, refresh, session_id } = response.data;
    setAccessToken(access);
    localStorage.setItem('refresh_token', refresh);
    localStorage.setItem('session_id', session_id);
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const logout = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const sessionId = localStorage.getItem('session_id');
    if (refreshToken) {
      try {
        await apiClient.post('logout/', { refresh: refreshToken, session_id:sessionId });
      } catch (error) {
        console.error('Failed to log out on server:', error);
      }
    }
    clearToken();
    localStorage.removeItem('refresh_token');
    window.location.href = '/login';
  }

  export const api_url = API_BASE_URL

export default apiClient