import React from 'react';
import { GlobeAltIcon, CurrencyDollarIcon, ChartBarIcon } from '@heroicons/react/20/solid';

const ForPublishers = ({ getTranslation }) => {
  const features = [
    {
      name: getTranslation('audience_engagement'),
      description: getTranslation('audience_engagement_description'),
      icon: GlobeAltIcon,
    },
    {
      name: getTranslation('monetization'),
      description: getTranslation('monetization_description'),
      icon: CurrencyDollarIcon,
    },
    {
      name: getTranslation('data_insights'),
      description: getTranslation('data_insights_description'),
      icon: ChartBarIcon,
    },
  ];

  return (
    <div className="overflow-hidden bg-white" id="forpublishers">
      <div className="mx-auto w-full">
        <div className="flex-col flex lg:flex-row items-center justify-center">
          {/* Features Section */}
          <div className="grid p-6 w-full flex-col lg:w-1/3">
            <div className="bg-indigo-100 p-4 rounded-lg bg-opacity-50">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('how_it_works')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('how_it_works_description_publishers')}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('book_licenses')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('book_licenses_description')}
              </p>
            </div>
          </div>

          {/* Icon-Based Features */}
          <div className="grid max-w-xl flex-col w-full lg:w-1/3 p-6 justify-center">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex items-center justify-center mt-6">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600 text-white mr-2">
                  <feature.icon className="h-12 w-12" aria-hidden="true" />
                </div>
                <div>
                  <p className="ml-12 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p className="ml-12 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForPublishers;
