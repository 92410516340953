import React from 'react';
import { BookOpenIcon, MusicalNoteIcon, SparklesIcon } from '@heroicons/react/20/solid';

const ForUsers = ({ getTranslation }) => {
  const features = [
    {
      name: getTranslation('personalized_soundtracks'),
      description: getTranslation('personalized_soundtracks_description'),
      icon: MusicalNoteIcon,
    },
    {
      name: getTranslation('dynamic_progression'),
      description: getTranslation('dynamic_progression_description'),
      icon: SparklesIcon,
    },
    {
      name: getTranslation('easy_integration'),
      description: getTranslation('easy_integration_description'),
      icon: BookOpenIcon,
    },
  ];

  return (
    <div className="bg-white" id="forusers">
      <div className="mx-auto w-full">
        <div className="flex-col flex lg:flex-row items-center justify-center">
          {/* Features Section */}
          <div className="grid p-6 w-full flex-col lg:w-1/3">
            <div className="bg-indigo-100 p-4 rounded-lg bg-opacity-50">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('how_it_works')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('how_it_works_description_users')}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('requirements')}
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                {getTranslation('requirements_description')}
              </p>
            </div>
          </div>

          {/* Icon-Based Features */}
          <div className="grid flex-col w-full lg:w-1/3 p-6 justify-center">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex items-center justify-center mt-6">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600 text-white mr-2">
                  <feature.icon className="h-20 w-20" aria-hidden="true" />
                </div>
                <div>
                  <p className="ml-12 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p className="ml-12 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Coming Soon Section */}
          <div className="text-center w-full lg:w-1/3 p-6 flex justify-center">
            <div className="bg-yellow-100 rounded-lg bg-opacity-50 p-4 lg:w-2/3">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                {getTranslation('exciting_features_coming_soon')}
              </h3>
              <ul className="mt-8 space-y-2 text-base leading-7 text-gray-600">
                <li>{getTranslation('character_profiles')}</li>
                <li>{getTranslation('character_map')}</li>
                <li>{getTranslation('physical_book_support')}</li>
                <li>{getTranslation('upload_personal_library')}</li>
                <li>{getTranslation('author_selected_soundtracks')}</li>
                <li>{getTranslation('offline_access')}</li>
                <li>{getTranslation('multi_device_sync')}</li>
                <li>{getTranslation('music_selection_worldwide')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForUsers;
